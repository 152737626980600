@charset "utf-8";

@import url("https://fonts.googleapis.com/css?family=Nunito");

$family-primary: "Nunito", sans-serif;
$dark: hsl(0, 0%, 14%);
$text: hsl(0, 0%, 14%);
$navbar-item-img-max-height: 6rem;
$navbar-height: 7rem;
$navbar-item-hover-color: none;
$navbar-item-hover-background-color: none;
$modal-content-width: 1000px;
$fa-font-path: "~font-awesome/fonts";

@import "~font-awesome/scss/font-awesome";

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/layout/_all.sass";
@import "~bulma/sass/grid/columns.sass";

@import "~bulma/sass/components/media.sass";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/components/modal.sass";

@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/title.sass";
@import "~bulma/sass/elements/image.sass";

@import "~leaflet/dist/leaflet.css";
