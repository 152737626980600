@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

#hero {
  padding-top: 6rem;
  padding-bottom: 6rem;

  .column {
    background-color: rgba(0, 0, 0, 0.75);
    @include mobile {
      padding: 3rem 0; }

    @include tablet {
      padding: 3rem; } }

  p {
    margin: 1.5rem 0;
    line-height: 1.25; }

  .button.is-black.is-inverted.is-outlined:hover,
  .button.is-black.is-inverted.is-outlined:focus {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff; } }
