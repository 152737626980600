@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

#about {
  padding-top: 7.25rem;
  padding-bottom: 7.25rem;
  img {
    border-radius: 100%; }

  h2 {
    margin-top: 1.5rem; }

  a {
    margin-top: 1.5rem; }

  @include mobile {
    .columns {
      text-align: center; } }

  @include desktop {
    .column:last-child {
      padding-left: 3rem; } }

  .button.is-dark:hover,
  .button.is-dark:focus {
    background-color: rgba(0, 0, 0, 0.9); } }
