@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

#creations {
  padding-top: 7.25rem;
  padding-bottom: 7.25rem;
  .modal {
    .columns {
      margin: 0;
      padding: 0;
      .column {
        margin: 0;
        padding: 0; }

      .column:last-child {
        padding: 3rem; } }

    @include touch {
      .modal-content {
        width: 80%;
        text-align: center; } } }

  .columns {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .column {
      div {
        position: relative;
        h2 {
          z-index: 1;
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          -webkit-transition: all 0.4s ease-in-out 0s;
          -moz-transition: all 0.4s ease-in-out 0s;
          transition: all 0.4s ease-in-out 0s; }
        img {
          -webkit-filter: brightness(75%);
          -moz-filter: brightness(75%);
          filter: brightness(75%);
          -webkit-transition: all 0.4s ease-in-out 0s;
          -moz-transition: all 0.4s ease-in-out 0s;
          transition: all 0.4s ease-in-out 0s; } }

      div:hover {
        cursor: pointer;
        h2 {
          opacity: 1; }
        img {
          -webkit-filter: brightness(50%);
          -moz-filter: brightness(50%);
          filter: brightness(50%); } } }

    @include tablet {
      .column {
        padding: 2rem; } }

    @include mobile {
      .column {
        padding: 0.75rem; } } }

  .button.is-black.is-inverted.is-outlined:hover,
  .button.is-black.is-inverted.is-outlined:focus {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff; } }
