@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

#contact {
  padding-top: 7.5rem;
  padding-bottom: 7.25rem;
  .map {
    height: 31.25rem;
    width: 100%;
    z-index: 0; }

  li {
    margin-top: 1.875rem; }

  li:first-child {
    a {
      cursor: default; } }

  @include mobile {
    ul {
      margin-bottom: 2rem; } }

  @include tablet {
    .columns {
      margin-top: 3.125rem;
      flex-direction: row-reverse; }

    ul {
      margin-left: 2.5rem; }

    li:first-child {
      margin-top: 0; } } }
