@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

.navbar-brand {
  img {
    height: 6rem; } }

.navbar-burger {
  background-color: transparent !important; }

.custom-navbar-item {
  a:hover,
  a:focus {
    border-bottom: 1px solid $black-bis; } }

@include touch {
  .navbar-menu {
    height: 100vh; }
  .navbar-end {
    padding-top: 1.5rem;
    .navbar-item {
      font-size: 1.5rem;
      padding: 0.8rem 2.5rem;
      i {
        font-size: 2rem; } } } }

@include desktop {
  .navbar-end {
    .navbar-item {
      font-size: 1.1rem;
      padding: 0 1rem;
      i {
        font-size: 1.5rem; } } } }
